<template>
  <page-layout class="ml-24 mr-24 mt-16">
    <!-- 列表 -->
    <div class="bg-white">
      <p-table rowKey="id" :dataSource="dataSource" dataKey="modal">
        <p-t-column type="seq" width="80" title="序号" />
        <p-t-column field="group_name" min-width="120" title="服务名称" />
        <p-t-column field="status_desc" min-width="120" title="状态" />
        <p-t-column min-width="80" title="操作">
          <template v-slot="{ row }">
            <a-button
              v-if="row.service_code === 'GPS' || row.service_code === 'ETC'"
              type="link"
              style="padding:0;"
              @click="handleActionClick(row)"
              >{{ row.status === "APPROVED" ? "停用" : "开通" }}</a-button
            >
          </template>
        </p-t-column>
        <p-t-column min-width="220" title="说明">
          <template v-slot="{ row }">
            <p>{{ descriptions[row.service_code] }}</p>
          </template>
        </p-t-column>
      </p-table>
    </div>

    <a-modal
      v-model:visible="changeModal"
      :width="420"
      :closable="false"
      @cancel="handleChangeStatusModalCancel"
      @ok="handleChangeStatusModal"
    >
      <div class="text-center">
        是否确认{{
          `${changeModalData.status === "REJECTED" ? "开启" : "停用"}`
        }}此项服务？
        <div
          style="color: red"
          v-if="changeModalData.status !== 'REJECTED'"
          class="mt-10"
        >
          注：关停后，您将无法正常调用此服务，请谨慎操作！
        </div>
      </div>
    </a-modal>
  </page-layout>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { getColumns, descriptions } from "./constants/table-config";
import {
  useCompanySubscriptionListApi,
  useCompanySubscriptionUpdateStatusApi
} from "@/apis/land";

export default defineComponent({
  name: "service-management",

  setup() {
    const tableColumns = reactive(getColumns());
    const tableDataSource = reactive(getInitTable());

    const companySubscriptionUpdateStatus = useCompanySubscriptionUpdateStatusApi();
    const companySubscriptionListApi = useCompanySubscriptionListApi();

    const changeModalData = ref({});
    const changeModal = ref(false);

    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          start_time: +new Date(),
          end_time: +new Date()
        };
        const res = await companySubscriptionListApi(data);
        return { records: res.company_subscription_bos };
      }
    });
    console.log(tableDataSource);

    const handleActionClick = row => {
      changeModalData.value = row;
      changeModal.value = true;
    };

    const handleChangeStatusModalCancel = () => {
      changeModal.value = false;
    };

    // 改变状态函数
    const handleChangeStatusModal = async () => {
      changeModal.value = false;
      try {
        const res = await companySubscriptionUpdateStatus({
          company_id: changeModalData.value.company_id,
          service_code: changeModalData.value.service_code,
          status:
            changeModalData.value.status === "APPROVED"
              ? "REJECTED"
              : "APPROVED"
        });
        if (res) {
          getConsultingList();
        }
      } catch (err) {
        console.log(err + 123);
      }
    };

    return {
      changeModal,
      changeModalData,
      tableColumns,
      descriptions,
      getConsultingList,
      dataSource: tableDataSource,
      handleActionClick,
      handleChangeStatusModalCancel,
      handleChangeStatusModal
    };
  }
});
</script>
