//table列配置
export const getColumns = () => {
  const columns = [
    {
      type: "checkbox",
      hidden: true
    },
    { width: 80, type: "seq", title: "序号" },
    {
      title: "服务名称",
      dataIndex: "group_name",
      minWidth: 120
    },
    {
      title: "状态",
      dataIndex: "status_desc",
      minWidth: 120
    },
    {
      title: "操作",
      dataIndex: "action",
      minWidth: 80
    },
    {
      title: "说明",
      dataIndex: "description",
      minWidth: 220
    }
  ];
  return columns;
};

export const descriptions = {
  GPS: "服务停用后，将无法获取轨迹，请谨慎操作",
  ETC: "服务停用后，将无法获取ETC发票，请谨慎操作",
  DATA_CHECK: "数据自检为网络货运基础服务，默认开通不支持停用",
  GAS: "油气服务如暂不需要，不使用即可，无需停用",
  INSURANCE: "保险服务如暂不需要，不使用即可，无需停用"
};
